<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#82DCC7;"
      d="M350.541,424.787c-43.196,26.652-96.56,36.827-149.853,24.206
	C94.101,423.748,28.161,316.879,53.404,210.292s132.114-172.527,238.7-147.284c104.606,24.775,170.052,128.17,148.587,232.758
	l-24.395-5.777l28.222,94.215L512,312.656l-24.568-5.819C514.996,176.44,433.577,47.178,303.168,16.291
	C170.781-15.063,38.042,66.841,6.687,199.227s50.549,265.126,182.936,296.481c66.193,15.677,132.475,3.04,186.128-30.065"
    />
    <polygon
      style="fill:#FED159;"
      points="320.74,206.115 320.74,177.679 267.883,177.679 267.883,141.05 239.448,141.05 
	239.448,177.679 186.592,177.679 186.592,270.218 292.304,270.218 292.304,305.886 186.592,305.886 186.592,334.32 239.448,334.32 
	239.448,370.951 267.883,370.951 267.883,334.32 320.74,334.32 320.74,241.783 215.028,241.783 215.028,206.115 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
